<template>
        <b-container class="p-4">
            <b-row>
                <b-col>
                    <div class="text-center">
                        <router-link to="home">
                            <img style="height: 4.25rem; width: 20rem" src="SmartRacks.svg" class="rounded mb-2">
                        </router-link>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <div class="header">
                        <div class="text-uppercase title text-dark">Change Password</div>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <div>
                        <ul class="list-unstyled">
                            <li>
                                Password must have <u><span class="font-italic font-weight-bold">at least</span></u>
                                8 characters
                            </li>
                        </ul>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <dx-form
                            ref="form"
                            :form-data="formData"
                            :disabled="loading"
                    >
                        <dx-simple-item
                                data-field="Password"
                                editor-type="dxTextBox"
                                :editor-options="{ placeholder: 'New Password', mode: 'password', width: '100%', height: 35 }"
                        >
                            <dx-label :visible="false"/>

                            <template #default>
                                <dx-text-box
                                        placeholder="New Password"
                                        width="100%"
                                        :input-attr="{ autocomplete: 'new-user' }"
                                        :height="35"
                                        :mode.sync="newPasswordMode"
                                        :value.sync="formData.NewPassword"
                                        :disabled.sync="validating"
                                        @initialized="onInitialized"
                                >
                                    <DxTextBoxButton
                                            :options="newPasswordButton"
                                            name="password"
                                            location="after"
                                    />
                                </dx-text-box>
                            </template>
                        </dx-simple-item>

                        <dx-simple-item
                                data-field="ConfirmationPassword"
                                editor-type="dxTextBox"
                                :editor-options="{ placeholder: 'Confirm New Password', mode: 'password', width: '100%', height: 35 }"
                        >
                            <dx-label :visible="false"/>

                            <template #default>
                                <dx-text-box
                                        placeholder="Confirm New Password"
                                        width="100%"
                                        :input-attr="{ autocomplete: 'new-user' }"
                                        :height="35"
                                        :mode.sync="confirmPasswordMode"
                                        :value.sync="formData.ConfirmationPassword"
                                        :disabled.sync="validating"
                                >
                                    <DxTextBoxButton
                                            :options="confirmPasswordButton"
                                            name="password"
                                            location="after"
                                    />
                                </dx-text-box>
                            </template>
                        </dx-simple-item>

                        <dx-button-item>
                            <dx-button-options
                                width="100%"
                                type="default"
                                text="Change Password"
                                :height="35"
                                :on-click="onSubmit"
                            >
                            </dx-button-options>
                        </dx-button-item>
                    </dx-form>
                </b-col>
            </b-row>
        </b-container>
</template>

<script>
/* eslint-disable */

import DxForm, {
    DxSimpleItem,
    DxLabel,
    DxButtonItem,
    DxButtonOptions,
    DxCompareRule,
    DxRequiredRule,
    DxStringLengthRule,
} from 'devextreme-vue/form';

import {DxTextBox, DxButton as DxTextBoxButton} from 'devextreme-vue/text-box';

import {API} from "@/API";

export default {
    components: {
        DxForm,
        DxSimpleItem,
        DxLabel,
        DxButtonItem,
        DxButtonOptions,
        DxRequiredRule,
        DxCompareRule,
        DxStringLengthRule,
        DxTextBoxButton,
        DxTextBox
    },

    data() {
        return {
            formData: {},
            loading: false,

            validating: false,

            newPasswordMode: 'password',
            newPasswordButton: {
                icon: 'eye.png',
                type: 'default',

                onClick: (e) => {
                    this.newPasswordMode = this.newPasswordMode === 'text' ? 'password' : 'text';
                }
            },

            confirmPasswordMode: 'password',
            confirmPasswordButton: {
                icon: 'eye.png',
                type: 'default',

                onClick: (e) => {
                    this.confirmPasswordMode = this.confirmPasswordMode === 'text' ? 'password' : 'text';
                }
            },
        }
    },

    methods: {
        onSubmit() {
            this.loading = true

            if(this.formData.NewPassword.length < 8 || this.formData.ConfirmationPassword.length < 8) {
                this.$toast.open({
                    message: 'Password(s) must be at least 8 characters.',
                    type: 'error',
                });
            } else if(this.formData.NewPassword !== this.formData.ConfirmationPassword) {
                this.$toast.open({
                    message: 'Passwords do not match.',
                    type: 'error',
                });
            } else {
                API.put('/user/password/change', {Password: this.formData.NewPassword, DeviceType: 'vue-app'})
                        .then((response) => {
                            this.$router.push('home')
                        })
                        .catch((error) => {
                            this.$toast.open({
                                message: error.response.data.message,
                                type: 'error',
                            });
                        })
            }

            this.loading = false
        },

        onInitialized(e) {
            setTimeout(function () {
                e.component.focus();
            }, 0);
        },

        passwordComparison() {
            return this.formData.NewPassword === this.formData.ConfirmationPassword
        }
    }
}
</script>

<style scoped>
::v-deep .dx-form-validation-summary {
    padding-top: 10px !important;
}
</style>

<style lang="scss">
@import "../../themes/generated/variables.base";
@import "../../fm-styles";

.header {
    text-align: center;
    margin-bottom: 20px;

    .icon {
        color: $FuelMastersAccentRed;
        font-size: 10rem;
        margin: 0
    }

    .title {
        color: $FuelMastersBlue;
        font-size: 22px;
        margin: 0;
    }
}
</style>
